import _functionCall from "../internals/function-call";
import _fixRegexpWellKnownSymbolLogic from "../internals/fix-regexp-well-known-symbol-logic";
import _anObject from "../internals/an-object";
import _requireObjectCoercible from "../internals/require-object-coercible";
import _sameValue from "../internals/same-value";
import _toString from "../internals/to-string";
import _getMethod from "../internals/get-method";
import _regexpExecAbstract from "../internals/regexp-exec-abstract";
var call = _functionCall;
var fixRegExpWellKnownSymbolLogic = _fixRegexpWellKnownSymbolLogic;
var anObject = _anObject;
var requireObjectCoercible = _requireObjectCoercible;
var sameValue = _sameValue;
var toString = _toString;
var getMethod = _getMethod;
var regExpExec = _regexpExecAbstract; // @@search logic

fixRegExpWellKnownSymbolLogic("search", function (SEARCH, nativeSearch, maybeCallNative) {
  return [// `String.prototype.search` method
  // https://tc39.es/ecma262/#sec-string.prototype.search
  function search(regexp) {
    var O = requireObjectCoercible(this);
    var searcher = regexp == undefined ? undefined : getMethod(regexp, SEARCH);
    return searcher ? call(searcher, regexp, O) : new RegExp(regexp)[SEARCH](toString(O));
  }, // `RegExp.prototype[@@search]` method
  // https://tc39.es/ecma262/#sec-regexp.prototype-@@search
  function (string) {
    var rx = anObject(this);
    var S = toString(string);
    var res = maybeCallNative(nativeSearch, rx, S);
    if (res.done) return res.value;
    var previousLastIndex = rx.lastIndex;
    if (!sameValue(previousLastIndex, 0)) rx.lastIndex = 0;
    var result = regExpExec(rx, S);
    if (!sameValue(rx.lastIndex, previousLastIndex)) rx.lastIndex = previousLastIndex;
    return result === null ? -1 : result.index;
  }];
});
export default {};